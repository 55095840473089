<template>
  <div
    class="custom-control custom-checkbox d-flex order-item"
    v-bind:class="{
      'jobedit-open': selectedJob ? selectedJob.item.id === item.id : '',
      'z-index-detail': statusDropdownOpen,
    }"
  >
    <div id="order-item-detail" class="flex-grow-1">
      <div class="rd-deep-1" :class="visible ? 'expanded' : ''">
        <b-container fluid>
          <b-row>
            <b-col cols="4" class="d-flex align-items-center">
              <div class="rdd1-part--with-icon">
                <div class="rdd1-part rdd1-part__icon">
                  <b-button
                    variant="icon"
                    @click="visible = !visible"
                    class="btn-icon--collapse"
                    :class="visible ? '' : 'collapsed'"
                    v-if="hasMoreDetails"
                  >
                    <i class="di-arrow-down-12 when-closed"></i>
                    <i class="di-arrow-up-12 when-opened"></i>
                  </b-button>
                </div>
                <div class="rdd1-part__content">
                  <div class="font-weight-medium">{{ productName }}</div>
                  <div>
                    <small class="text-muted">{{ productOptions }}</small>
                  </div>
                  <div>
                    <small class="text-muted">Order Item ID: {{ orderItemID }}</small>
                  </div>
                  <div v-if="historicalDataPurpose">
                    <small class="text-muted"
                      >Purpose: {{ historicalDataPurpose.toUpperCase() }}</small
                    >
                  </div>

                  <div v-if="isOctoposOrder">
                    <small class="text-muted">Item Price: ${{ orderItemPrice.toFixed(2) }}</small>
                  </div>

                  <div v-if="Ismiscellaneous">
                    <small class="text-muted">Detail: {{ miscellaneousDetail }}</small>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col cols="1" class="d-flex align-items-center">
              <span v-if="processedItem.quantity > 1" class="text-nowrap">
                Qty: {{ processedItem.quantity }}
              </span>
            </b-col>
            <b-col cols="2" class="d-flex align-items-center">
              <b-dropdown
                class="status-dropdown"
                menu-class="dropdown-menu--status"
                toggle-class="btn--badge-pill"
                :disabled="orderStatus === 'cancelled'"
              >
                <template v-slot:button-content v-if="processedItem.outlet">
                  @ {{ processedItem.outlet.abbreviation }}
                </template>
                <b-dropdown-item-button
                  v-for="outlet in selectableOutlets"
                  :key="outlet.id"
                  @click="onOutletChange(outlet.id)"
                  :active="processedItem.outlet && processedItem.outlet.id === outlet.id"
                >
                  <span class="ml-2">{{ outlet.name }}</span>
                </b-dropdown-item-button>
              </b-dropdown>
            </b-col>
            <b-col cols="3" class="d-flex align-items-center">
              <b-dropdown
                :id="'dropdown-' + item.id"
                class="status-dropdown"
                menu-class="dropdown-menu--status"
                toggle-class="btn--badge-pill"
                :variant="
                  getStatusVariant(item.quantity === -1 ? 'refunded' : item.dispense_status_id)
                "
                :disabled="orderStatus === 'cancelled'"
              >
                <template v-slot:button-content>
                  {{ processedItem.dispenseStatusDisplay }}
                </template>
                <div v-if="nextDispenseStatus" class="dropdown-group">
                  <div class="dropdown-group__title">Move status to next</div>
                  <b-dropdown-item-button @click="onStatusChange(nextDispenseStatus.slug)">
                    <span class="dot dot--sm dot--secondary mr-2" />
                    <span>{{ nextDispenseStatus.display_name }}</span>
                  </b-dropdown-item-button>
                  <b-dropdown-divider></b-dropdown-divider>
                </div>
                <b-dropdown-item-button
                  v-for="status in selectableItemStatuses"
                  :key="status.slug"
                  :variant="getStatusVariant(item.dispense_status_id)"
                  @click="onStatusChange(status.slug)"
                >
                  <span :class="getStatusVariant(status.slug)" class="dot dot--sm mr-2" />
                  <span>{{ status.display_name }}</span>
                </b-dropdown-item-button>
              </b-dropdown>
            </b-col>
            <b-col cols="2">
              <b-button
                variant="icon-text-transparent"
                class="comments-button"
                @click="openCommentsModal(item)"
              >
                <span class="icon" :class="{ active: item.notes && item.notes.length > 0 }">
                  <i class="di-comment-18" />
                </span>
                <span class="text">
                  {{ item.notes && item.notes.length }}
                  {{ (item.notes && item.notes.length) > 2 ? 'Notes' : 'Note' }}
                </span>
              </b-button>
            </b-col>
          </b-row>
          <b-brow>
            <b-col style="margin-left: 31px" cols="12">
              <small class="text-muted" v-if="hoyaStatusText">
                <strong>Hoya Order:</strong> <span v-html="hoyaStatusText" />
              </small>
            </b-col>
          </b-brow>
          <b-brow>
            <b-col style="margin-left: 31px" cols="12">
              <small class="text-muted" v-if="shamirStatusText">
                <strong>Shamir Order:</strong> <span v-html="shamirStatusText" />
              </small>
            </b-col>
          </b-brow>
        </b-container>
      </div>
      <b-collapse :visible="visible" v-if="hasMoreDetails">
        <div class="rd-deep-2" v-if="loading" style="display: flex; justify-content: center">
          <b-spinner />
        </div>

        <div class="rd-deep-2" v-if="!loading">
          <b-container fluid>
            <b-row>
              <b-col cols="4" v-if="frame">
                <h6 class="text-uppercase">Frame</h6>
                <span class="dot dot--black" v-if="frame.sizeId">{{
                  frame.sizeId === 'extra-small' ? 'XS' : frame.sizeId.charAt(0)
                }}</span>
                <span
                  v-if="frame.sizeId != 'Non-Dresden'"
                  class="dot ml-1"
                  :style="{
                    borderColor: this._.get(frame, 'color.hex_code') || '#fff',
                    backgroundColor: this._.get(frame, 'color.hex_code') || '#fff',
                  }"
                />
                <span class="ml-2" v-if="frame.sizeId !== 'Non-Dresden'">
                  <span>{{ this._.get(frame, 'color.name') }}</span>
                </span>
                <span class="ml-2" v-else> Non-Dresden </span>
              </b-col>

              <b-col cols="8">
                <b-col cols="12" v-if="hasArm">
                  <h6 class="text-uppercase">Right Arm</h6>
                  <span class="dot dot--black" v-if="rightArm.sizeId">{{
                    rightArm.sizeId === 'extra-small' ? 'XS' : rightArm.sizeId.charAt(0)
                  }}</span>
                  <span
                    v-if="(frame && frame.sizeId !== 'Non-Dresden') || !frame"
                    class="dot ml-1"
                    :class="
                      this._.get(rightArm, 'color.name')
                        ? rightArm.color.name.toLowerCase() === 'white'
                          ? 'dot--white'
                          : ''
                        : ''
                    "
                    :style="{
                      borderColor: this._.get(rightArm, 'color.hex_code') || '#fff',
                      backgroundColor: this._.get(rightArm, 'color.hex_code') || '#fff',
                    }"
                  />
                  <span class="ml-2" v-if="(frame && frame.sizeId !== 'Non-Dresden') || !frame">
                    {{ this._.get(rightArm, 'color.name') }}
                  </span>
                  <span class="ml-2" v-else> Non-Dresden </span>
                </b-col>

                <b-col cols="12" v-if="hasArm" style="margin-top: 10px">
                  <h6 class="text-uppercase">Left Arm</h6>
                  <span class="dot dot--black" v-if="leftArm.sizeId">{{
                    leftArm.sizeId === 'extra-small' ? 'XS' : leftArm.sizeId.charAt(0)
                  }}</span>
                  <span
                    v-if="(frame && frame.sizeId !== 'Non-Dresden') || !frame"
                    class="dot ml-1"
                    :class="
                      this._.get(leftArm, 'color.name')
                        ? leftArm.color.name.toLowerCase() === 'white'
                          ? 'dot--white'
                          : ''
                        : ''
                    "
                    :style="{
                      borderColor: this._.get(leftArm, 'color.hex_code') || '#fff',
                      backgroundColor: this._.get(leftArm, 'color.hex_code') || '#fff',
                    }"
                  />
                  <span class="ml-2" v-if="(frame && frame.sizeId !== 'Non-Dresden') || !frame">
                    {{ this._.get(leftArm, 'color.name') }}
                  </span>
                  <span class="ml-2" v-else> Non-Dresden </span>
                </b-col>
              </b-col>
            </b-row>

            <b-row v-if="isExistingDresden" style="flex-direction: column">
              <b-col>
                <h6 class="text-uppercase">Options</h6>
                <span>Existing Dresden</span>
              </b-col>
            </b-row>

            <b-row v-if="dredenGoPurpose && dresdenGoPower" style="flex-direction: column">
              <b-col>
                <h6 class="text-uppercase">DresdenGo Options</h6>
                <p>Purpose: {{ dredenGoPurpose.toUpperCase() }}</p>
                <p>Power: {{ dresdenGoPower.toUpperCase() }}</p>
              </b-col>
            </b-row>

            <b-row v-show="isPrescriptionRequired">
              <b-col cols="4" class="mt-5" v-if="prescription">
                <h6 class="text-uppercase">Prescription</h6>
                <p v-if="prescription.detail">
                  <PrescriptionDetail
                    :prescription="prescription.detail"
                    :lensesToPick="item.additional_info.lensesToPick"
                  />
                </p>

                <div v-if="prescriptionNote">
                  <h6 class="text-uppercase">Prescription Note</h6>
                  <p>{{ prescriptionNote }}</p>
                </div>
              </b-col>
              <b-col cols="8" class="mt-5">
                <b-row>
                  <b-col cols="5" v-if="pd">
                    <h6 class="text-uppercase">
                      {{ isMultifocal ? 'Width' : 'Pupil Distance' }}
                    </h6>
                    <p>
                      <PupilDistanceDetail :pd="pd" type="width" :sourceMessage="sourceMessage" />
                    </p>
                  </b-col>
                  <b-col cols="5" v-if="pd && hasPdHeight">
                    <h6 class="text-uppercase">
                      {{ isMultifocal ? 'Height' : 'Pupil Height' }}
                    </h6>
                    <p>
                      <PupilDistanceDetail :pd="pd" type="height" :sourceMessage="sourceMessage" />
                    </p>
                  </b-col>
                  <b-col cols="2" v-if="prescriptionTypeId">
                    <h6 class="text-uppercase">Purpose</h6>
                    <span class="text-capitalize">
                      {{ prescriptionTypeId }}
                    </span>
                  </b-col>
                  <div v-if="presenterNote">
                    <p class="warning mr-2 mt-3">{{ presenterNote }}</p>
                  </div>
                </b-row>
              </b-col>
            </b-row>
            <b-row v-show="isPrescriptionRequired" class="mt-3">
              <b-col cols="4" v-if="lenses">
                <h6 class="text-uppercase">Lenses</h6>
                <div>
                  <span>{{ lenses.name }}</span>
                  <br />
                  <div
                    class="lenses-to-pick-box"
                    v-if="isSingleVision && item.additional_info.lensesToPick"
                  >
                    <div>
                      <b>R</b>
                      <span v-html="item.additional_info.lensesToPick.right.substr(1)"></span>
                    </div>
                    <div>
                      <b>L</b>
                      <span v-html="item.additional_info.lensesToPick.left.substr(1)"></span>
                    </div>
                    <div v-if="item.additional_info.lensesToPick.add">
                      <b v-html="item.additional_info.lensesToPick.add" />
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col cols="8">
                <b-row>
                  <b-col cols="4" v-if="lensCoating">
                    <h6 class="text-uppercase">Coating(s)</h6>
                    <div>
                      <span>{{ lensCoating }}</span>
                    </div>
                    <div v-if="newLensCoating">
                      <hr />
                      <span>{{ newLensCoating }}</span>
                    </div>
                  </b-col>
                  <b-col cols="4" v-if="lensColor">
                    <h6 class="text-uppercase">Colour</h6>
                    <div>
                      <span>{{ lensColor }}</span>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <PrismDisplay v-if="prescription" :prescription="prescription.detail" class="mt-3" />
            <div v-if="isContactLens">
              <div>
                <span>Contact Prescription - {{ contactLensType }}</span>
                <br />
                <div class="lenses-to-pick-box" v-if="contactLensType !== 'Bundle'">
                  <div
                    v-for="(key, contactLensPrescriptionData) in contactLensPrescription"
                    v-bind:key="key"
                  >
                    <div>
                      <b>{{ contactLensPrescriptionData.toUpperCase().replace('MF', 'ADD') }}</b>
                      <span v-html="key"></span>
                    </div>
                  </div>
                </div>

                <b-row class="mt-3" v-if="contactLensType == 'Bundle'">
                  <b-col cols="6">
                    <div class="lenses-to-pick-box">
                      <p>Right</p>
                      <div
                        v-for="(key, contactLensPrescriptionData) in contactLensPrescriptionR"
                        v-bind:key="key"
                      >
                        <div>
                          <b>{{
                            contactLensPrescriptionData.toUpperCase().replace('MF', 'ADD')
                          }}</b>
                          <span v-html="key"></span>
                        </div>
                      </div>
                    </div>
                  </b-col>

                  <b-col cols="6">
                    <div class="lenses-to-pick-box">
                      <p>Left</p>
                      <div
                        v-for="(key, contactLensPrescriptionData) in contactLensPrescriptionL"
                        v-bind:key="key"
                      >
                        <div>
                          <b>{{
                            contactLensPrescriptionData.toUpperCase().replace('MF', 'ADD')
                          }}</b>
                          <span v-html="key"></span>
                        </div>
                      </div>
                    </div>
                  </b-col>
                </b-row>

                <div v-if="hasContactLensPrescriptionFile">
                  Prescription File
                  <div>
                    <b-button
                      variant="outline-secondary"
                      size="sm"
                      @click="handleViewFileContactLensPrescription"
                    >
                      <svgicon name="paperclip" />
                      <span class="ml-1">View File</span>
                    </b-button>
                    <FileModal />
                  </div>
                </div>
              </div>
            </div>
          </b-container>
        </div>
        <div v-if="item.next_scheduled_date" class="rd-deep-2-actions">
          <div class="buttons-wrapper">
            <b-row>
              <b-col class="mt-auto mb-auto">
                <p class="text-left mb-0">
                  Next lenses replenishment is &nbsp;{{
                    item.next_scheduled_date | moment('from', 'now')
                  }}
                </p>
              </b-col>
              <b-col> </b-col>
            </b-row>
          </div>
        </div>
      </b-collapse>
    </div>
    <input
      :id="'item-' + processedItem.id"
      type="checkbox"
      class="custom-control-input"
      :value="processedItem.id"
      @change="handleSelectItemChange"
      :checked="selectedOrderItemIds.includes(processedItem.id)"
    />
    <label :for="'item-' + processedItem.id" class="custom-control-label order-item-label" />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import FileModal from '@/components/modals/FileModal.vue';
import apiOrders from '@/api/orders';
import apiNotes from '@/api/notes';
import PrismDisplay from '@/components/PrismDisplay.vue';
import PupilDistanceDetail from './PupilDistanceDetail.vue';
import PrescriptionDetail from './PrescriptionDetail.vue';

export default {
  name: 'OrderItemListDetail',
  components: {
    PrescriptionDetail,
    PupilDistanceDetail,
    PrismDisplay,
    FileModal,
  },
  props: {
    order: Object,
    item: Object,
    invoice: String,
    orderStatus: String,
  },
  data() {
    return {
      visible: false,
      monthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      statusDropdownOpen: false,
      loading: false,
      contactLensPrescriptionFile: null,
    };
  },
  mounted() {
    const dropdownId = `dropdown-${this.item.id}`;
    this.$root.$on(`bv::dropdown::show`, bvEvent => {
      if (bvEvent.componentId === dropdownId) {
        this.statusDropdownOpen = true;
      }
    });

    this.$root.$on(`bv::dropdown::hide`, bvEvent => {
      if (bvEvent.componentId === dropdownId) {
        this.statusDropdownOpen = false;
      }
    });

    if (this.hasContactLensPrescriptionFile) {
      this.getContactLensPrescriptionFileInfo();
    }
  },
  computed: {
    ...mapState({
      orderItemStatuses: state => state.orderItemStatuses,
      currentUser: state => state.auth.user,
      selectedJob: state => state.jobsModule.selectedJobData,
    }),
    ...mapGetters(['selectableItemStatuses', 'selectableOutlets', 'selectedOrderItemIds']),
    processedItem() {
      const item = { ...this.item };
      let dispenseStatus = this.item.dispense_status_id;
      if (this.item.quantity === -1) {
        dispenseStatus = 'refunded';
      }
      if (this.order.dispense_status_id === 'voided') {
        dispenseStatus = 'voided';
      }
      item.dispenseStatusDisplay = this.getStatusDisplayName(
        dispenseStatus,
        this.orderItemStatuses,
      );
      return item;
    },
    hoyaStatusText() {
      if (!this.item?.hoya_status || this.item?.hoya_status.length === 0) return '';
      let orderDateText = '';
      if (
        this.item.hoya_status.orderDeliveryDate &&
        Number(this.item.hoya_status.orderStatus) > 0
      ) {
        const orderDate = new Date(this.item.hoya_status.orderDeliveryDate);
        orderDateText = `<br><strong>Expected Delivery Date:</strong> ${orderDate.getDate()} ${
          this.monthNames[orderDate.getMonth()]
        } ${orderDate.getFullYear()}`;
      }

      return `${this.item.hoya_status.orderStatusMessage} (${this.item.hoya_status.orderId}) ${orderDateText}`;
    },
    shamirStatusText() {
      const { shamir_status: statuses } = this.item || {};
      if (!statuses || statuses.length === 0) return '';

      const shamirStatus = statuses[0];
      const { statusName, updated_at, estimatedShippingDate, tracking } = shamirStatus;

      const orderDate = new Date(updated_at);
      let orderDateText = `<br><strong>Updated Date:</strong> ${orderDate.getDate()} ${
        this.monthNames[orderDate.getMonth()]
      } ${orderDate.getFullYear()}`;

      if (estimatedShippingDate) {
        const estimatedDate = new Date(estimatedShippingDate);
        orderDateText += ` <br><strong>Expected Delivery Date:</strong> ${estimatedDate.getDate()} ${
          this.monthNames[estimatedDate.getMonth()]
        } ${estimatedDate.getFullYear()}`;
      }

      let trackingText = '';
      if (tracking) {
        trackingText = `<br><a href="${tracking.trackingLink}" target="_blank"><strong>Tracking:</strong> ${tracking.courierName} ${tracking.trackingNo}</a>`;
      }

      return `${statusName} ${orderDateText}${trackingText}`;
    },
    checked() {
      return this.$store.getters.selectedOrderItemIds.findIndex(id => id === this.item.id) > -1;
    },
    nextDispenseStatus() {
      const index = this.selectableItemStatuses.findIndex(
        status => status.slug === this.item.dispense_status_id,
      );
      return index > -1 ? this.selectableItemStatuses[index + 1] : null;
    },
    productName() {
      const productName = this._.get(this.item, 'region_product.name', '');
      const segments = productName.split('/');
      return this._.get(segments, '0').trim();
    },
    productOptions() {
      const productName = this._.get(this.item, 'region_product.name', '');
      const segments = productName.split('/');
      return segments.slice(1).join('/');
    },
    frame() {
      return this._.get(this.item, 'additional_info.data.customize.component.Frame');
    },
    leftArm() {
      return this._.get(this.item, 'additional_info.data.customize.component.Temple Left');
    },
    rightArm() {
      return this._.get(this.item, 'additional_info.data.customize.component.Temple Right');
    },

    isContactLens() {
      const getType = this._.get(this.item, 'additional_info.data.productType');
      if (getType && getType === 'contact-lens') {
        return true;
      }
      return false;
    },
    contactLensType() {
      return this._.get(this.item, 'additional_info.data.contactLensType');
    },
    hasContactLensPrescriptionFile() {
      return this._.get(this.item, 'additional_info.data.prescriptionImage');
    },
    contactLensPrescription() {
      const prescription = this._.get(this.item, 'additional_info.data.prescription');

      const result = {
        bc: prescription.bc,
        dia: prescription.dia,
        sph: prescription.sph,
        cyl: prescription.cyl,
        axis: prescription.axis,
        add: prescription.add,
        mf: prescription.mf,
        addtype: prescription.addtype,
      };

      // Remove properties with undefined values
      Object.keys(result).forEach(key => result[key] === undefined && delete result[key]);

      return result;
    },
    contactLensPrescriptionR() {
      const prescription = this._.get(this.item, 'additional_info.data.prescriptionR');

      const result = {
        bc: prescription.bc,
        dia: prescription.dia,
        sph: prescription.sph,
        cyl: prescription.cyl,
        axis: prescription.axis,
        add: prescription.add,
        mf: prescription.mf,
        addtype: prescription.addtype,
      };

      // Remove properties with undefined values
      Object.keys(result).forEach(key => result[key] === undefined && delete result[key]);

      return result;
    },
    contactLensPrescriptionL() {
      const prescription = this._.get(this.item, 'additional_info.data.prescriptionL');

      const result = {
        bc: prescription.bc,
        dia: prescription.dia,
        sph: prescription.sph,
        cyl: prescription.cyl,
        axis: prescription.axis,
        add: prescription.add,
        mf: prescription.mf,
        addtype: prescription.addtype,
      };

      // Remove properties with undefined values
      Object.keys(result).forEach(key => result[key] === undefined && delete result[key]);

      return result;
    },
    hasArm() {
      return this.leftArm || this.rightArm;
    },
    prescription() {
      return this._.get(this.item, 'additional_info.data.prescription');
    },
    prescriptionNote() {
      return this._.get(this.prescription, 'detail.note');
    },
    prescriptionTypeId() {
      const typeId = this._.get(this.prescription, 'typeId');
      return typeId;
    },
    historicalDataPurpose() {
      const typeId = this._.get(
        this.item,
        'additional_info.data.historical_data.prescription.typeId',
      );
      return typeId;
    },
    dredenGoPurpose() {
      const typeId = this._.get(
        this.item,
        'additional_info.data.historical_data.woocommerce.Lens.attributes.attribute_pa_treatment',
      );
      return typeId;
    },
    dresdenGoPower() {
      const typeId = this._.get(
        this.item,
        'additional_info.data.historical_data.woocommerce.Lens.attributes.attribute_pa_power',
      );
      return typeId;
    },
    miscellaneousDetail() {
      return this._.get(
        this.item,
        'additional_info.data.historical_data.woocommerce.product_description',
      );
    },
    Ismiscellaneous() {
      const productType = this._.get(
        this.item,
        'additional_info.data.historical_data.woocommerce.product_type',
      );
      return productType === 'miscellaneous';
    },
    sourceMessage() {
      const sourceId = this._.get(this.item, 'additional_info.data.pd.detail.source_id');
      if (sourceId && this.$store.state.pdSources) {
        const selectedSource = this.$store.state.pdSources.find(
          source => source.slug === 'o-pm' && source.id === sourceId,
        );
        if (selectedSource) {
          return true;
        }
      }
      return false;
    },
    pd() {
      return (
        this._.get(this.item, 'additional_info.pdPresenter') ||
        this._.get(this.item, 'additional_info.data.pd')
      );
    },
    presenterNote() {
      return this._.get(this.item, 'additional_info.data.pd.detail.note');
    },
    hasPdHeight() {
      return !!this._.get(this.pd, 'data.height.right');
    },
    lensAddonCombination() {
      return this._.get(this.item, 'additional_info.data.lens_addon_combination');
    },
    lenses() {
      return this._.get(this.lensAddonCombination, 'lenses');
    },
    productImage() {
      const path = this._.get(this.item, 'region_product.product.image_path');
      if (!path) {
        return null;
      }
      return this.getImageUrl(path);
    },
    hasMoreDetails() {
      return (
        this.frame ||
        this.hasArm ||
        this.prescription ||
        this.pd ||
        this.lenses ||
        this.isContactLens
      );
    },
    isMultifocal() {
      return this._.get(this.item, 'region_product.product.type.lenses_type') === 'multifocal';
    },
    isSingleVision() {
      return this._.get(this.item, 'region_product.product.type.lenses_type') === 'single-vision';
    },
    lensColor() {
      return this._.get(this.item, 'additional_info.lens_colour');
    },
    lensCoating() {
      return this._.get(this.lenses, 'coating');
    },
    newLensCoating() {
      return this._.get(this.item, 'additional_info.data.lens_addon_combination.coating.name');
    },
    isPrescriptionRequired() {
      return this.item.region_product.requires_prescription;
    },
    file() {
      if (typeof this.prescription !== 'undefined') {
        if (this.prescription.detail.files) {
          return this.prescription.detail.files[0];
        }
      }
      return null;
    },
    orderItemID() {
      return this._.get(this.item, 'id');
    },
    orderItemPrice() {
      return this._.get(this.item, 'historical_unit_price');
    },
    isOctoposOrder() {
      return this._.get(this.order, 'is_octopos_order');
    },
    isExistingDresden() {
      return this._.get(
        this.item,
        'additional_info.data.customize.component.Frame.existingdresden',
      );
    },
  },
  methods: {
    ...mapMutations({
      setSelectedPrescription: 'SET_SELECTED_PRESCRIPTION',
      setSelectedFile: 'SET_SELECTED_FILE',
    }),
    ...mapActions(['setSelectedOrderItems']),
    onStatusChange(id) {
      apiOrders
        .updateOrderItemDispenseStatusId(this.item.id, id)
        .then(({ data: { data: job } }) => {
          this.$store.commit('UPDATE_JOB_IN_JOBS', job);
        });
    },
    onOutletChange(id) {
      const outlet = this.selectableOutlets.find(o => o.id === id);

      this.item.outlet = outlet;

      apiOrders.updateOrderItemsDispenseOutletId([this.item.id], id).then(() => {
        this.$store.commit('UPDATE_JOB_IN_JOBS', this.order);
      });
    },
    openCommentsModal(orderItem) {
      const { customer } = this.order;
      const invoiceNumber = this.invoice;
      let title = this.getCustomerName(customer);
      title += invoiceNumber ? ` — ${invoiceNumber}` : '';
      title += this.productName ? ` — ${this.productName}` : '';
      this.$store.commit('commentModal/SHOW_COMMENT_MODAL', {
        vm: this,
        title,
        createCommentsCallback: noteContent =>
          apiNotes.createOrderItemNote(orderItem.id, this.currentUser.id, noteContent).then(() => {
            this.$root.$emit('reloadExpandedJob', this.order.id);
          }),
        fetchCommentsCallback: () => apiNotes.getOrderNotes(orderItem.id, 'orderItem'),
        removeCommentCallback: noteId =>
          apiNotes.deleteOrderNote(orderItem.id, noteId, 'orderItem'),
      });
    },
    handleSelectItemChange() {
      this.setSelectedOrderItems({ ...this.item, customer: this.order.customer });
    },
    handleViewFile() {
      this.setSelectedPrescription(this.prescription.detail);
      this.setSelectedFile(this.file);
      this.$bvModal.show('file-modal');
    },
    handleViewFileContactLensPrescription() {
      this.setSelectedFile(this.contactLensPrescriptionFile);
      this.$bvModal.show('file-modal');
    },
    getContactLensPrescriptionFileInfo() {
      this.loading = true;
      apiOrders
        .getContactLensPrescriptionFile(this.hasContactLensPrescriptionFile)
        .then(({ data }) => {
          this.contactLensPrescriptionFile = data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
