<template>
  <div
    class="step-holder"
    v-bind:class="{ completed: activeJobEditStep > 3, active: activeJobEditStep === 3 }"
  >
    <div class="title-bar" style="display: flex; justify-content: space-between">
      <h3><span :class="{ invalid: isPdInvalid }">3. Pupil Distance</span></h3>
      <div>
        <a class="ml-4" @click="editThisStep" v-if="activeJobEditStep > 3 && this.customer"
          >Change</a
        >
        <a class="ml-4" @click="resetThisStep" v-if="activeJobEditStep > 3 && this.customer"
          >Reset</a
        >

        <a @click="editPd()" v-if="!customer && selectedPd">Guest Edit</a>
      </div>
    </div>

    <v-wait for="Pd List">
      <template slot="waiting">
        <div class="text-center mb-3">
          <b-spinner></b-spinner>
        </div>
      </template>
      <div style="width: 100%">
        <template v-if="activeJobEditStep > 3 && selectedPd">
          <div class="pd-detail-box" style="width: 100%">
            <h6 class="text-uppercase">{{ isMultifocal ? 'Width' : '' }}</h6>
            <p>
              <PupilDistanceDetail
                :pd="selectedPd.pdPresenter"
                type="width"
                :sourceMessage="sourceMessage"
              ></PupilDistanceDetail>
            </p>
          </div>
          <div class="pd-detail-box ml-3" v-if="hasPdHeight">
            <h6 class="text-uppercase">{{ isMultifocal ? 'Height' : '' }}</h6>
            <p>
              <PupilDistanceDetail
                :pd="selectedPd.pdPresenter"
                type="height"
                :sourceMessage="sourceMessage"
              ></PupilDistanceDetail>
            </p>
          </div>
          <div v-if="presenterNote">
            <p class="warning mr-2 mt-3">{{ presenterNote }}</p>
          </div>
        </template>

        <ValidationObserver v-slot="{ handleSubmit, invalid }">
          <b-form @submit.stop.prevent="handleSubmit(sendPdForm)" v-if="activeJobEditStep === 3">
            <ValidationProvider name="Pupil Distance" rules="required" v-slot="validationContext">
              <PupilDistanceList
                v-model="$store.state.jobsModule.selectedPd"
                :customer="{ ...customer, ...customerPds }"
                :showTitle="true"
                :selectable="true"
                :expanded="true"
                :collapsible="true"
                @new="showPdModal"
                @edit="showPdEditModal"
              ></PupilDistanceList>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>

            <div class="confirmation-wrapper">
              <b-button class="mr-3" variant="outline-secondary" @click="cancel" type="button"
                >Cancel</b-button
              >
              <b-button
                class="button-with-loader"
                type="submit"
                :disabled="invalid || isPdInvalid"
                variant="primary"
                >Confirm</b-button
              >
            </div>
          </b-form>
        </ValidationObserver>
      </div>
    </v-wait>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import PupilDistanceList from '../../PupilDistanceList.vue';
import PupilDistanceDetail from '../../PupilDistanceDetail.vue';

export default {
  name: 'PupilDistance',
  props: {
    lenses: Object,
    additionalInfo: Object,
    customerPds: Object,
    customer: Object,
    item: Object,
  },
  computed: {
    ...mapState({
      activeJobEditStep: state => state.jobsModule.activeJobEditStep,
      selectedPd: state => state.jobsModule.selectedPd,
    }),
    isPdInvalid() {
      return !!this.selectedPd?.error || !!this.selectedPd?.pdPresenter?.error;
    },
    sourceMessage() {
      const sourceId = this._.get(this.selectedPd, 'detail.source_id');
      if (sourceId && this.$store.state.pdSources) {
        const selectedSource = this.$store.state.pdSources.find(
          source => source.slug === 'o-pm' && source.id === sourceId,
        );
        if (selectedSource) {
          return true;
        }
      }
      return false;
    },

    pdType() {
      if (this.selectedPd && this.selectedPd.pdPresenter && this.selectedPd.pdPresenter.height) {
        return 'height';
      }
      return 'width';
    },
    isMultifocal() {
      return this._.get(this.item, 'region_product.product.type.lenses_type') === 'multifocal';
    },
    hasPdHeight() {
      return this.selectedPd?.pdPresenter?.data?.height?.right;
    },
    presenterNote() {
      return this._.get(this.selectedPd?.detail, 'note');
    },
  },
  components: {
    PupilDistanceList,
    PupilDistanceDetail,
    ValidationProvider,
    ValidationObserver,
  },
  methods: {
    editPd() {
      this.$emit('show-pd-edit-modal', {
        pd: this.selectedPd.detail,
        isOnPageEdit: true,
      });
    },
    showPdModal(e) {
      this.$emit('show-pd-modal', e);
    },
    editThisStep() {
      this.$store.commit('jobsModule/SET_ACTIVE_JOB_EDIT_STEP', 3);
    },
    sendPdForm() {
      this.$emit('pdDataSent', this.selectedPd);
      this.$store.commit('jobsModule/SET_ACTIVE_JOB_EDIT_STEP', 4);
    },
    cancel() {
      this.$emit('pdDataSent', this.selectedPd);
      this.$store.commit('jobsModule/SET_ACTIVE_JOB_EDIT_STEP', 4);
    },
    resetThisStep() {
      this.$store.commit('jobsModule/SET_ACTIVE_JOB_EDIT_STEP', 3);
      this.$store.commit('jobsModule/SET_SELECTED_PD', null);
    },
    showPdEditModal(e) {
      this.$emit('show-pd-edit-modal', e);
    },
  },
};
</script>
