<template>
  <div
    class="step-holder"
    v-bind:class="{ completed: activeJobEditStep > 2, active: activeJobEditStep === 2 }"
  >
    <div class="title-bar">
      <div style="width: 100%">
        <div style="display: flex; justify-content: space-between">
          <h3><span>2. Prescription</span></h3>
          <div>
            <a
              @click="editOnPagePrescription(selectedPrescription.id)"
              v-if="activeJobEditStep > 2 && prescriptions.length > 0"
              >Edit</a
            >
            <a
              @click="editOnPagePrescription(selectedPrescription.id)"
              v-if="activeJobEditStep > 2 && !customer && selectedPrescription"
              >Guest Edit</a
            >

            <a
              @click="
                showPrescriptionModal({
                  customer: {
                    id: null,
                    full_name: null,
                  },
                })
              "
              v-if="activeJobEditStep > 2 && !customer && !selectedPrescription"
              >Add Prescription</a
            >

            <a class="ml-4" @click="editThisStep" v-if="activeJobEditStep > 2 && customer"
              >Change</a
            >
            <a class="ml-4" @click="resetThisStep" v-if="activeJobEditStep > 2 && customer"
              >Reset</a
            >
          </div>
        </div>

        <v-wait for="Prescription List">
          <template slot="waiting">
            <div class="text-center mb-3">
              <b-spinner></b-spinner>
            </div>
          </template>
          <PrescriptionDetail
            class="prescription-detail-box"
            v-if="activeJobEditStep > 2"
            :prescription="selectedPrescription"
            :editOnPagePrescription="editOnPagePrescription"
            :lensesToPick="lensesToPick"
          ></PrescriptionDetail>

          <div
            v-if="
              (productType.lenses_type.includes('single-vision') ||
                productType.key.includes('single-vision')) &&
              activeJobEditStep > 2 &&
              selectedPrescription
            "
          >
            <h6 class="text-uppercase mt-2-5">Purpose</h6>
            <b-form-group class="flex-fill">
              <b-form-radio-group
                class="custom-radio-button"
                id="type"
                v-model="selectedPrescriptionTypePurpose"
                @input="sendPrescriptionForm"
                buttons="buttons"
                button-variant="text"
              >
                <b-form-radio value="reading">Reading</b-form-radio>
                <b-form-radio
                  v-if="
                    selectedPrescription &&
                    (selectedPrescription.add_int ||
                      (selectedPrescription.right_int_add && selectedPrescription.left_int_add))
                  "
                  value="intermediate"
                  >Intermediate</b-form-radio
                >
                <b-form-radio value="distance">Distance</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </div>
        </v-wait>
      </div>
    </div>
    <ValidationObserver v-slot="{ handleSubmit, invalid }">
      <b-form
        @submit.stop.prevent="handleSubmit(sendPrescriptionForm)"
        v-if="activeJobEditStep === 2"
      >
        <v-wait for="Prescription List">
          <template slot="waiting">
            <div class="text-center mb-3">
              <b-spinner></b-spinner>
            </div>
          </template>
          <ValidationProvider name="Prescription" rules="required" v-slot="validationContext">
            <PrescriptionList
              :customer="customerPrescriptions"
              :showTitle="true"
              :selectable="true"
              :collapsible="true"
              :expanded="true"
              v-model="$store.state.jobsModule.selectedPrescription"
              :state="getValidationState(validationContext)"
              @new="showPrescriptionModal"
              @edit="showPrescriptionEditModal"
              :editOnPagePrescription="editOnPagePrescription"
            ></PrescriptionList>
            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </ValidationProvider>
        </v-wait>

        <ValidationProvider
          v-if="
            productType.lenses_type.includes('single-vision') ||
            productType.key.includes('single-vision')
          "
          name="Prescription type"
          rules="required"
          v-slot="validationContext"
        >
          <b-form-group class="flex-fill" label="Type" label-for="type">
            <b-form-radio-group
              class="custom-radio-button"
              id="type"
              v-model="selectedPrescriptionTypePurpose"
              :state="getValidationState(validationContext)"
              buttons="buttons"
              button-variant="text"
            >
              <b-form-radio value="reading">Reading</b-form-radio>
              <b-form-radio
                v-if="
                  selectedPrescription &&
                  (selectedPrescription.add_int ||
                    (selectedPrescription.right_int_add && selectedPrescription.left_int_add))
                "
                value="intermediate"
                >Intermediate</b-form-radio
              >
              <b-form-radio value="distance">Distance</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
          <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </ValidationProvider>

        <div class="confirmation-wrapper">
          <b-button
            class="mr-3"
            variant="outline-secondary"
            @click="setActiveJobEditStep(5)"
            type="button"
            >Cancel</b-button
          >
          <b-button
            class="button-with-loader"
            type="submit"
            :disabled="invalid || !!(selectedPrescription && selectedPrescription.error)"
            variant="primary"
            >Confirm</b-button
          >
        </div>
      </b-form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import apiPds from '@/api/pds';
import PrescriptionList from '../../PrescriptionList.vue';
import apiPrescriptions from '../../../api/prescriptions';
import PrescriptionDetail from '../../PrescriptionDetail.vue';

export default {
  name: 'Prescription',
  props: {
    customer: Object,
    productType: Object,
    lensType: Object,
    lensesToPick: Object,
  },
  data() {
    return {
      customerPrescriptions: {},
      prescriptions: [],
      prescriptionTypeOptions: [
        {
          text: 'Reading',
          value: 'reading',
        },
        {
          text: 'Intermediate',
          value: 'intermediate',
        },
        {
          text: 'Distance',
          value: 'distance',
        },
      ],
    };
  },
  computed: {
    ...mapState({
      activeJobEditStep: state => state.jobsModule.activeJobEditStep,
      selectedPrescription: state => state.jobsModule.selectedPrescription,
      selectedPrescriptionType: state => state.jobsModule.selectedPrescriptionType,
      selectedPd: state => state.jobsModule.selectedPd,
    }),
    ...mapMutations({
      setSelectedPrescription: 'SET_SELECTED_PRESCRIPTION',
    }),
    selectedPrescriptionTypePurpose: {
      get() {
        return this.selectedPrescriptionType;
      },
      set(value) {
        this.$store.commit('jobsModule/SET_SELECTED_PRESCRIPTION_TYPE', value);
      },
    },
  },
  components: {
    PrescriptionList,
    ValidationProvider,
    ValidationObserver,
    PrescriptionDetail,
  },
  mounted() {
    this.$root.$on('prescriptionListMutated', (prescriptionId = null) => {
      if (this.customer) {
        if (prescriptionId) {
          this.getPrescriptions(prescriptionId);
        } else {
          this.getPrescriptions();
        }
      }
    });
  },
  created() {
    if (this.customer) {
      this.getPrescriptions();
    }
  },
  methods: {
    showPrescriptionModal(e) {
      if (!this.customer) {
        this.selectedPrescriptionTypePurpose = 'distance';
      }
      this.$emit('show-prescription-modal', e);
    },
    showPrescriptionEditModal(e) {
      this.$emit('show-prescription-edit-modal', e);
    },
    editOnPagePrescription(id) {
      if (this.customer) {
        this.$emit('show-prescription-edit-modal', {
          prescription: this.prescriptions.filter(p => p.id === id)[0],
          isOnPageEdit: true,
        });
      } else {
        this.$emit('show-prescription-edit-modal', {
          prescription: this.selectedPrescription,
          isOnPageEdit: true,
        });
      }
    },
    sendPrescriptionForm() {
      this.$emit('prescriptionDataSent', {
        prescription: { ...this.selectedPrescription },
        prescriptionType: this.selectedPrescriptionTypePurpose,
      });
      if (this.customer) {
        this.$emit('customerPd');
      } else if (this._.get(this.lensType, 'id')) {
        this.$wait.start('Pd List');
        this.$wait.start('Prescription List');

        apiPds
          .newGetPd(
            this.selectedPd.detail.id,
            this.selectedPrescriptionTypePurpose,
            this._.get(this.lensType, 'id'),
          )
          .then(response => {
            this.$store.commit('jobsModule/SET_SELECTED_PD', {
              detail: response.data.data,
              pdPresenter: response.data.data.pdPresenter,
            });
          })
          .finally(() => {
            this.$wait.end('Pd List');
            this.$wait.end('Prescription List');
          });
      }

      if (this.customer) {
        this.$store.commit('jobsModule/SET_ACTIVE_JOB_EDIT_STEP', 3);
      }
    },
    editThisStep() {
      this.$store.commit('jobsModule/SET_ACTIVE_JOB_EDIT_STEP', 2);
    },
    resetThisStep() {
      this.$store.commit('jobsModule/SET_ACTIVE_JOB_EDIT_STEP', 2);
      this.$store.dispatch('jobsModule/resetPrescription');
    },
    getPrescriptions(prescriptionId = null) {
      this.$wait.start('Prescription List');
      apiPrescriptions
        .getCustomerPrescription(this.customer.id)
        .then(response => {
          const prescriptions = response.data.data;
          this.customerPrescriptions = {
            full_name: `${this.customer.first_name} ${this.customer.last_name}`,
            prescriptions: { data: prescriptions },
          };
          this.prescriptions = prescriptions;
          if (prescriptionId) {
            const prescriptionObj = response.data.data.filter(
              prescription => prescription.id === prescriptionId,
            )[0];
            this.$store.commit('jobsModule/SET_SELECTED_PRESCRIPTION', prescriptionObj);
          }
        })
        .catch(({ response }) => {
          this.errors = response.data.error;
          this.$store.dispatch('showErrorAlert', response.statusText);
        })
        .finally(() => {
          this.$wait.end('Prescription List');
        });
    },
  },
};
</script>
