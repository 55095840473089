<template>
  <ValidationObserver v-slot="{ passes }">
    <b-modal
      :id="showFile ? 'modal-fullscreen-bg' : 'new-prescription-modal'"
      size="xl"
      ref="newPrescriptionModal"
      @ok="onOk"
      @show="onShow"
      @hide="onHide"
      :modal-class="showFile ? 'modal-fullscreen' : ''"
    >
      <template v-slot:modal-header="{ close }">
        <b-container>
          <div class="modal-title">
            <h3 class="text-bold text-center">{{ isEdit ? 'Edit' : 'New' }} Prescription</h3>
            <h6 class="text-uppercase text-center">{{ customer && customer.full_name }}</h6>
            <button type="button" aria-label="Close" class="close" @click="close">
              <i class="di-remove-10" />
            </button>
          </div>
        </b-container>
      </template>

      <b-row>
        <b-col cols="6" v-if="showFile">
          <div
            :style="
              form.files[0].file_name.split('.').reverse()[0] === 'pdf'
                ? 'width: 100%; height: 100%;'
                : 'width: 100%; height: 100%; position: fixed; left: -25%'
            "
          >
            <iframe
              v-if="form.files[0].file_name.split('.').reverse()[0] === 'pdf'"
              :src="form.files[0].full_path + '#view=FitH'"
              frameBorder="0"
              scrolling="auto"
              height="100%"
              width="100%"
            ></iframe>
            <vue-magnifier
              v-if="
                imageExtensions.includes(
                  form.files[0].file_name.split('.').reverse()[0].toLowerCase(),
                )
              "
              :src="form.files[0].full_path"
              :srcLarge="form.files[0].full_path"
            />
          </div>
        </b-col>
        <b-col :cols="showFile ? 6 : 12">
          <b-container>
            <ul class="text-danger" v-if="errors.length">
              <li v-for="(error, index) in errors" v-bind:key="index">{{ error }}</li>
            </ul>
            <h5 class="mb-4">Prescription details</h5>
            <b-form>
              <section>
                <b-form-group label="Type" label-for="type" class="flex-fill">
                  <b-form-radio-group
                    id="type"
                    v-model="form.type_id"
                    :options="$store.state.prescriptionTypes"
                    buttons
                    button-variant="white"
                    class="custom-radio-button"
                  />
                </b-form-group>
              </section>
              <section v-if="form.type_id == 1">
                <div class="divider mt-2 mb-4"></div>
                <section>
                  <b-row>
                    <b-col cols="6">
                      <h6 class="text-uppercase mb-3">Right</h6>
                      <div class="row--2px">
                        <b-col cols="3">
                          <b-form-group label="SPH" label-for="right-sph" label-class="text-center">
                            <b-form-select
                              id="right-sph"
                              v-model="form.right_sph"
                              :options="$store.state.spheres"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="3">
                          <ValidationProvider
                            vid="right_cylinder"
                            name="Right Cylinder"
                            mode="lazy"
                            rules="required_with:@right_axis,Right Axis"
                            v-slot="validationContext"
                          >
                            <b-form-group
                              label="CYL"
                              label-for="right-cyl"
                              label-class="text-center"
                            >
                              <b-form-select
                                id="right-cyl"
                                v-model="form.right_cyl"
                                :state="getValidationState(validationContext)"
                                :options="$store.state.cylinders"
                              />
                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col cols="3">
                          <ValidationProvider
                            vid="right_axis"
                            name="Right Axis"
                            mode="lazy"
                            rules="required_with:@right_cylinder,Right Cylinder|between:1,180"
                            v-slot="validationContext"
                          >
                            <b-form-group
                              label="AXS"
                              label-for="right-axis"
                              label-class="text-center"
                            >
                              <b-form-input
                                id="right-axis"
                                class="input-axs"
                                type="number"
                                v-disable-increment
                                min="1"
                                max="180"
                                v-model="form.right_axis"
                                :state="getValidationState(validationContext)"
                              />
                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </div>
                    </b-col>
                    <b-col cols="6">
                      <h6 class="text-uppercase mb-3">Left</h6>
                      <div class="row--2px">
                        <b-col cols="3">
                          <b-form-group label="SPH" label-for="left-sph" label-class="text-center">
                            <b-form-select
                              id="left-sph"
                              v-model="form.left_sph"
                              :options="$store.state.spheres"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="3">
                          <ValidationProvider
                            vid="left_cylinder"
                            name="Left Cylinder"
                            mode="lazy"
                            rules="required_with:@left_axis,Left Axis"
                            v-slot="validationContext"
                          >
                            <b-form-group
                              label="CYL"
                              label-for="left-cyl"
                              label-class="text-center"
                            >
                              <b-form-select
                                id="left-cyl"
                                v-model="form.left_cyl"
                                :state="getValidationState(validationContext)"
                                :options="$store.state.cylinders"
                              />
                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col cols="3">
                          <ValidationProvider
                            vid="left_axis"
                            name="Left Axis"
                            mode="lazy"
                            rules="required_with:@left_cylinder,Left Cylinder|between:1,180"
                            v-slot="validationContext"
                          >
                            <b-form-group
                              label="AXS"
                              label-for="left-axis"
                              label-class="text-center"
                            >
                              <b-form-input
                                id="left-axis"
                                class="input-axs"
                                type="number"
                                v-disable-increment
                                min="1"
                                max="180"
                                v-model="form.left_axis"
                                :state="getValidationState(validationContext)"
                              />
                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </div>
                    </b-col>
                  </b-row>
                </section>
                <div class="divider mt-3 mb-4"></div>
                <section>
                  <h6 class="text-uppercase mb-3">Add</h6>
                  <b-form-radio-group
                    id="is-monocular"
                    v-model="isMonocular"
                    :options="addTypes"
                    buttons
                    button-variant="white"
                    class="custom-radio-button mb-3"
                  />
                  <b-row v-show="!isMonocular">
                    <b-col cols="2">
                      <b-form-group label="Near add" label-for="near-add">
                        <b-form-input
                          id="near-add"
                          name="near_add"
                          type="number"
                          v-disable-increment
                          v-model="near_add"
                          @change="updateBothNearAdd"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="2">
                      <b-form-group label="Int add" label-for="int-add">
                        <b-form-input
                          id="int-add"
                          name="int_add"
                          type="number"
                          v-disable-increment
                          v-model="int_add"
                          @change="updateBothIntAdd"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <div v-show="isMonocular">
                    <b-row>
                      <b-col cols="4">
                        <h6 class="text-uppercase mb-3">Right Add</h6>
                        <b-row>
                          <b-col cols="6">
                            <b-form-group label="Near add" label-for="right-near-add">
                              <b-form-input
                                id="right-near-add"
                                name="right_near_add"
                                type="number"
                                v-disable-increment
                                v-model="form.spectacles.right_near_add"
                                @input="
                                  form.spectacles.left_near_add = form.spectacles.right_near_add
                                "
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="6">
                            <b-form-group label="Int add" label-for="right-int-add">
                              <b-form-input
                                id="right-int-add"
                                name="right_int_add"
                                type="number"
                                v-disable-increment
                                v-model="form.spectacles.right_int_add"
                                @input="
                                  form.spectacles.left_int_add = form.spectacles.right_int_add
                                "
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col cols="4">
                        <h6 class="text-uppercase mb-3">Left Add</h6>
                        <b-row>
                          <b-col cols="6">
                            <b-form-group label="Near add" label-for="left-near-add">
                              <b-form-input
                                id="left-near-add"
                                name="left_near_add"
                                type="number"
                                v-disable-increment
                                v-model="form.spectacles.left_near_add"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="6">
                            <b-form-group label="Int add" label-for="left-int-add">
                              <b-form-input
                                id="left-int-add"
                                name="left_int_add"
                                type="number"
                                v-disable-increment
                                v-model="form.spectacles.left_int_add"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </div>
                </section>
                <div class="divider mt-1 mb-4"></div>
                <section>
                  <b-button variant="icon" v-b-toggle.prism>
                    <i class="di-arrow-down-12 when-closed" />
                    <i class="di-arrow-up-12 when-opened" />
                  </b-button>
                  <h5 class="d-inline-block mb-4 ml-2">Prism</h5>
                  <b-collapse id="prism">
                    <b-row>
                      <b-col cols="6">
                        <h6 class="text-uppercase mb-3">Horizontal Right</h6>
                      </b-col>
                      <b-col cols="6">
                        <h6 class="text-uppercase mb-3">Horizontal Left</h6>
                      </b-col>
                      <b-col cols="6">
                        <PrismInput
                          label="Near"
                          check-text="Out"
                          uncheck-text="In"
                          v-model="form.spectacles.right_horiz_near_prism"
                        />
                      </b-col>

                      <b-col cols="6">
                        <PrismInput
                          label="Near"
                          check-text="Out"
                          uncheck-text="In"
                          v-model="form.spectacles.left_horiz_near_prism"
                        />
                      </b-col>

                      <b-col cols="6">
                        <PrismInput
                          label="Distance"
                          check-text="Out"
                          uncheck-text="In"
                          v-model="form.spectacles.right_horiz_dist_prism"
                        />
                      </b-col>

                      <b-col cols="6">
                        <PrismInput
                          label="Distance"
                          check-text="Out"
                          uncheck-text="In"
                          v-model="form.spectacles.left_horiz_dist_prism"
                        />
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="6">
                        <h6 class="text-uppercase mb-3">Vertical Right</h6>
                      </b-col>
                      <b-col cols="6">
                        <h6 class="text-uppercase mb-3">Vertical Left</h6>
                      </b-col>

                      <b-col cols="6">
                        <PrismInput
                          label="Near"
                          check-text="Up"
                          uncheck-text="Down"
                          v-model="form.spectacles.right_vert_near_prism"
                        />
                      </b-col>
                      <b-col cols="6">
                        <PrismInput
                          label="Near"
                          check-text="Up"
                          uncheck-text="Down"
                          v-model="form.spectacles.left_vert_near_prism"
                        />
                      </b-col>
                      <b-col cols="6">
                        <PrismInput
                          label="Distance"
                          check-text="Up"
                          uncheck-text="Down"
                          v-model="form.spectacles.right_vert_dist_prism"
                        />
                      </b-col>

                      <b-col cols="6">
                        <PrismInput
                          label="Distance"
                          check-text="Up"
                          uncheck-text="Down"
                          v-model="form.spectacles.left_vert_dist_prism"
                        />
                      </b-col>
                    </b-row>
                  </b-collapse>
                </section>
                <div class="divider mb-4"></div>
                <section>
                  <!-- IOGO-336 -->
                  <!-- <div class="box box--info box--icon mb-4">
              <div class="box-icon">
                <i class="di-information-10" />
              </div>
              <div class="box-header">
                <div class="box-header__title">
                  <p class="text-semi-bold mb-2">Source options</p>
                  <p>Quisque quis velit ex. Integer lacinia vehicula neque at dapibus.</p>
                </div>
              </div>
            </div> -->
                  <ValidationProvider
                    vid="source"
                    name="Source"
                    mode="lazy"
                    rules="required"
                    v-slot="validationContext"
                  >
                    <b-form-group label="Source" label-for="source">
                      <b-form-radio-group
                        id="source"
                        v-model="form.source_id"
                        name="source_id"
                        buttons
                        class="custom-radio-tag"
                        :state="getValidationState(validationContext)"
                      >
                        <b-form-radio
                          v-for="source in $store.state.prescriptionSources"
                          v-bind:key="source.value"
                          :value="source.value"
                          button
                          :button-variant="source.color"
                        >
                          <span>{{ source.text }}</span>
                        </b-form-radio>
                      </b-form-radio-group>
                      <b-form-invalid-feedback class="d-block">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                  <b-row>
                    <b-col cols="6">
                      <b-form-group label="Outlet" label-for="outlet">
                        <b-form-select
                          id="outlet"
                          :options="$store.getters.outletOptions"
                          v-model="form.outlet_id"
                        >
                        </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group label="Expiry date" label-for="expiry">
                        <b-form-input
                          id="expiry"
                          name="expiry_date_stamp"
                          type="date"
                          v-model="form.expiry_date_stamp"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-form-group label="Notes" label-for="note">
                    <b-form-textarea
                      id="note"
                      v-model="form.note"
                      rows="3"
                      placeholder="Type your notes here..."
                    />
                  </b-form-group>
                  <b-form-group label="Printed Notes" label-for="printed-note" class="mb-4">
                    <b-form-textarea
                      id="printed-note"
                      v-model="form.printed_note"
                      rows="3"
                      placeholder="Type your notes here..."
                    />
                  </b-form-group>
                  <b-form-group
                    v-if="form.files && form.files.length"
                    label="File"
                    label-for="prescription-file"
                    class="mb-4"
                  >
                    <FileCard
                      id="prescription-file"
                      :files="form.files"
                      removable
                      @removeFile="removePrescriptionFile"
                    />
                  </b-form-group>
                </section>
                <div class="divider mb-4"></div>
                <section class="pb-4">
                  <ValidationProvider
                    name="File"
                    rules="ext:jpeg,jpg,png,bmp,pdf,doc,docx|fileSize:10024"
                    ref="fileValidator"
                  >
                    <h5>Upload a prescription copy</h5>
                    <b-form-file
                      ref="fileInput"
                      :value="file"
                      class="d-none"
                      plain
                      @change="handleFileInputChange"
                    />
                    <div v-if="file">
                      <b-button class="my-3 px-5" variant="success"> ✔ </b-button>
                      <span class="ml-3">Upload successfull!</span>
                      <b-button variant="link" @click="file = null">
                        <u>Re-upload</u>
                      </b-button>
                    </div>
                    <div v-else>
                      <b-button
                        class="my-3"
                        variant="outline-secondary"
                        @click="$refs.fileInput.$el.click()"
                      >
                        Upload a file
                      </b-button>
                      <b-button class="my-3 ml-2" variant="outline-secondary">
                        Take a photo
                      </b-button>
                    </div>
                    <FilePreview :file="file" :error="fileError" />
                  </ValidationProvider>
                </section>
              </section>

              <section v-if="form.type_id == 2">
                <div class="divider mt-2 mb-4"></div>
                <section>
                  <b-row>
                    <b-col cols="6">
                      <h6 class="text-uppercase mb-3">Right</h6>
                      <div class="row--2px">
                        <b-col cols="3">
                          <b-form-group label="SPH" label-for="right-sph" label-class="text-center">
                            <b-form-select
                              id="right-sph"
                              v-model="form.right_sph"
                              :options="$store.state.spheres"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="3">
                          <ValidationProvider
                            vid="right_cylinder"
                            name="Right Cylinder"
                            mode="lazy"
                            rules="required_with:@right_axis,Right Axis"
                            v-slot="validationContext"
                          >
                            <b-form-group
                              label="CYL"
                              label-for="right-cyl"
                              label-class="text-center"
                            >
                              <b-form-select
                                id="right-cyl"
                                v-model="form.right_cyl"
                                :state="getValidationState(validationContext)"
                                :options="$store.state.cylinders"
                              />
                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col cols="3">
                          <ValidationProvider
                            vid="right_axis"
                            name="Right Axis"
                            mode="lazy"
                            rules="required_with:@right_cylinder,Right Cylinder|between:1,180"
                            v-slot="validationContext"
                          >
                            <b-form-group
                              label="AXS"
                              label-for="right-axis"
                              label-class="text-center"
                            >
                              <b-form-input
                                id="right-axis"
                                class="input-axs"
                                type="number"
                                v-disable-increment
                                min="1"
                                max="180"
                                v-model="form.right_axis"
                                :state="getValidationState(validationContext)"
                              />
                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </div>
                    </b-col>
                    <b-col cols="6">
                      <h6 class="text-uppercase mb-3">Left</h6>
                      <div class="row--2px">
                        <b-col cols="3">
                          <b-form-group label="SPH" label-for="left-sph" label-class="text-center">
                            <b-form-select
                              id="left-sph"
                              v-model="form.left_sph"
                              :options="$store.state.spheres"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="3">
                          <ValidationProvider
                            vid="left_cylinder"
                            name="Left Cylinder"
                            mode="lazy"
                            rules="required_with:@left_axis,Left Axis"
                            v-slot="validationContext"
                          >
                            <b-form-group
                              label="CYL"
                              label-for="left-cyl"
                              label-class="text-center"
                            >
                              <b-form-select
                                id="left-cyl"
                                v-model="form.left_cyl"
                                :state="getValidationState(validationContext)"
                                :options="$store.state.cylinders"
                              />
                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col cols="3">
                          <ValidationProvider
                            vid="left_axis"
                            name="Left Axis"
                            mode="lazy"
                            rules="required_with:@left_cylinder,Left Cylinder|between:1,180"
                            v-slot="validationContext"
                          >
                            <b-form-group
                              label="AXS"
                              label-for="left-axis"
                              label-class="text-center"
                            >
                              <b-form-input
                                id="left-axis"
                                class="input-axs"
                                type="number"
                                v-disable-increment
                                min="1"
                                max="180"
                                v-model="form.left_axis"
                                :state="getValidationState(validationContext)"
                              />
                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </div>
                    </b-col>
                  </b-row>
                </section>

                <section>
                  <b-row>
                    <b-col cols="6">
                      <div class="row--2px">
                        <b-row>
                          <b-col cols="12">
                            <ValidationProvider
                              vid="right-contact_lens-add_near"
                              name="Right Add Near"
                              mode="lazy"
                              v-slot="validationContext"
                            >
                              <b-form-group
                                label="Add Near"
                                label-for="right-contact_lens-add_near"
                              >
                                <b-form-input
                                  id="right-contact_lens-add_near"
                                  name="contact_lens_right_supplier"
                                  type="text"
                                  v-model="form.contact_lens.right_near_add"
                                  :state="getValidationState(validationContext)"
                                />
                                <b-form-invalid-feedback>
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>

                          <b-col cols="12">
                            <ValidationProvider
                              vid="right-contact_lens-add_near"
                              name="Right Add Near"
                              mode="lazy"
                              v-slot="validationContext"
                            >
                              <b-form-group
                                label="Add Near Type"
                                label-for="right-contact_lens-add_near"
                              >
                                <b-form-select
                                  v-model="form.contact_lens.right_near_add_enum"
                                  :options="enum_types"
                                  :state="getValidationState(validationContext)"
                                  id="right-contact_lens-add_near_type"
                                  name="contact_lens_right_supplier_type"
                                ></b-form-select>

                                <b-form-invalid-feedback>
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>

                          <b-col cols="12">
                            <ValidationProvider
                              vid="right-contact_lens-supplier"
                              name="Right Supplier"
                              mode="lazy"
                              v-slot="validationContext"
                            >
                              <b-form-group
                                label="Supplier"
                                label-for="right-contact_lens-supplier"
                              >
                                <b-form-input
                                  id="right-contact_lens-supplier"
                                  name="contact_lens_right_supplier"
                                  type="text"
                                  v-model="form.contact_lens.right_supplier"
                                  :state="getValidationState(validationContext)"
                                />
                                <b-form-invalid-feedback>
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col cols="12">
                            <ValidationProvider
                              vid="right-contact_lens-type"
                              name="Right Type"
                              mode="lazy"
                              v-slot="validationContext"
                            >
                              <b-form-group label="Type" label-for="right-contact_lens-type">
                                <b-form-input
                                  id="right-contact_lens-type"
                                  name="contact_lens_right_type"
                                  type="text"
                                  v-model="form.contact_lens.right_type"
                                  :state="getValidationState(validationContext)"
                                />
                                <b-form-invalid-feedback>
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col cols="12">
                            <ValidationProvider
                              vid="right-contact_lens-brand"
                              name="Right Brand"
                              mode="lazy"
                              v-slot="validationContext"
                            >
                              <b-form-group label="Brand" label-for="right-contact_lens-brand">
                                <b-form-input
                                  id="right-contact_lens-brand"
                                  name="contact_lens_right_brand"
                                  type="text"
                                  v-model="form.contact_lens.right_brand"
                                  :state="getValidationState(validationContext)"
                                />
                                <b-form-invalid-feedback>
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col cols="12">
                            <ValidationProvider
                              vid="right-contact_lens-base-curve"
                              name="Right Base Curve"
                              mode="lazy"
                              v-slot="validationContext"
                            >
                              <b-form-group
                                label="Base Curve"
                                label-for="right-contact_lens-base-curve"
                              >
                                <b-form-input
                                  id="right-contact_lens-base-curve"
                                  name="contact_lens_right_base_curve"
                                  type="number"
                                  v-disable-increment
                                  v-model="form.contact_lens.right_base_curve"
                                  :state="getValidationState(validationContext)"
                                />
                                <b-form-invalid-feedback>
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col cols="12">
                            <ValidationProvider
                              vid="right-contact_lens-diameter"
                              name="Right Diameter"
                              mode="lazy"
                              v-slot="validationContext"
                            >
                              <b-form-group
                                label="Diameter"
                                label-for="right-contact_lens-diameter"
                              >
                                <b-form-input
                                  id="right-contact_lens-diameter"
                                  name="contact_lens_right_diameter"
                                  type="text"
                                  v-disable-increment
                                  v-model="form.contact_lens.right_diameter"
                                  :state="getValidationState(validationContext)"
                                />
                                <b-form-invalid-feedback>
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col cols="12">
                            <ValidationProvider
                              vid="right-contact_lens-color"
                              name="Right Color"
                              mode="lazy"
                              v-slot="validationContext"
                            >
                              <b-form-group label="Color" label-for="right-contact_lens-color">
                                <b-form-input
                                  id="right-contact_lens-color"
                                  name="contact_lens_right_color"
                                  type="text"
                                  v-model="form.contact_lens.right_colour"
                                  :state="getValidationState(validationContext)"
                                />
                                <b-form-invalid-feedback>
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col cols="12">
                            <ValidationProvider
                              vid="right-contact_lens-notes"
                              name="Right Notes"
                              mode="lazy"
                              v-slot="validationContext"
                            >
                              <b-form-group label="Notes" label-for="right-contact_lens-notes">
                                <b-form-input
                                  id="right-contact_lens-notes"
                                  name="contact_lens_right_notes"
                                  type="text"
                                  v-model="form.contact_lens.right_notes"
                                  :state="getValidationState(validationContext)"
                                />
                                <b-form-invalid-feedback>
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                      </div>
                    </b-col>
                    <b-col cols="6">
                      <div class="row--2px">
                        <b-row>
                          <b-col cols="12">
                            <ValidationProvider
                              vid="left-contact_lens-add_near"
                              name="Left Add Near"
                              mode="lazy"
                              v-slot="validationContext"
                            >
                              <b-form-group label="Add Near" label-for="left-contact_lens-add_near">
                                <b-form-input
                                  id="left-contact_lens-add_near"
                                  name="contact_lens_left_supplier"
                                  type="text"
                                  v-model="form.contact_lens.left_near_add"
                                  :state="getValidationState(validationContext)"
                                />
                                <b-form-invalid-feedback>
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>

                          <b-col cols="12">
                            <ValidationProvider
                              vid="left-contact_lens-add_near_enum"
                              name="Left Add Near"
                              mode="lazy"
                              v-slot="validationContext"
                            >
                              <b-form-group
                                label="Add Near Type"
                                label-for="left-contact_lens-add_near_enum"
                              >
                                <b-form-select
                                  v-model="form.contact_lens.left_near_add_enum"
                                  :options="enum_types"
                                  :state="getValidationState(validationContext)"
                                  id="left-contact_lens-add_near_enum"
                                  name="contact_lens_left_supplier_type_enum"
                                ></b-form-select>

                                <b-form-invalid-feedback>
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>

                          <b-col cols="12">
                            <ValidationProvider
                              vid="left-contact_lens-supplier"
                              name="left Supplier"
                              mode="lazy"
                              v-slot="validationContext"
                            >
                              <b-form-group label="Supplier" label-for="left-contact_lens-supplier">
                                <b-form-input
                                  id="left-contact_lens-supplier"
                                  name="contact_lens_left_supplier"
                                  type="text"
                                  v-model="form.contact_lens.left_supplier"
                                  :state="getValidationState(validationContext)"
                                />
                                <b-form-invalid-feedback>
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col cols="12">
                            <ValidationProvider
                              vid="left-contact_lens-type"
                              name="left Type"
                              mode="lazy"
                              v-slot="validationContext"
                            >
                              <b-form-group label="Type" label-for="left-contact_lens-type">
                                <b-form-input
                                  id="left-contact_lens-type"
                                  name="contact_lens_left_type"
                                  type="text"
                                  v-model="form.contact_lens.left_type"
                                  :state="getValidationState(validationContext)"
                                />
                                <b-form-invalid-feedback>
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col cols="12">
                            <ValidationProvider
                              vid="left-contact_lens-brand"
                              name="left Brand"
                              mode="lazy"
                              v-slot="validationContext"
                            >
                              <b-form-group label="Brand" label-for="left-contact_lens-brand">
                                <b-form-input
                                  id="left-contact_lens-brand"
                                  name="contact_lens_left_brand"
                                  type="text"
                                  v-model="form.contact_lens.left_brand"
                                  :state="getValidationState(validationContext)"
                                />
                                <b-form-invalid-feedback>
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col cols="12">
                            <ValidationProvider
                              vid="left-contact_lens-base-curve"
                              name="left Base Curve"
                              mode="lazy"
                              v-slot="validationContext"
                            >
                              <b-form-group
                                label="Base Curve"
                                label-for="left-contact_lens-base-curve"
                              >
                                <b-form-input
                                  id="left-contact_lens-base-curve"
                                  name="contact_lens_left_base_curve"
                                  type="number"
                                  v-disable-increment
                                  v-model="form.contact_lens.left_base_curve"
                                  :state="getValidationState(validationContext)"
                                />
                                <b-form-invalid-feedback>
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col cols="12">
                            <ValidationProvider
                              vid="left-contact_lens-diameter"
                              name="left Diameter"
                              mode="lazy"
                              v-slot="validationContext"
                            >
                              <b-form-group label="Diameter" label-for="left-contact_lens-diameter">
                                <b-form-input
                                  id="left-contact_lens-diameter"
                                  name="contact_lens_left_diameter"
                                  type="text"
                                  v-disable-increment
                                  v-model="form.contact_lens.left_diameter"
                                  :state="getValidationState(validationContext)"
                                />
                                <b-form-invalid-feedback>
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col cols="12">
                            <ValidationProvider
                              vid="left-contact_lens-colour"
                              name="left Colour"
                              mode="lazy"
                              v-slot="validationContext"
                            >
                              <b-form-group label="Color" label-for="left-contact_lens-colour">
                                <b-form-input
                                  id="left-contact_lens-colour"
                                  name="contact_lens_left_colour"
                                  type="text"
                                  v-model="form.contact_lens.left_colour"
                                  :state="getValidationState(validationContext)"
                                />
                                <b-form-invalid-feedback>
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col cols="12">
                            <ValidationProvider
                              vid="left-contact_lens-notes"
                              name="left Notes"
                              mode="lazy"
                              v-slot="validationContext"
                            >
                              <b-form-group label="Notes" label-for="left-contact_lens-notes">
                                <b-form-input
                                  id="left-contact_lens-notes"
                                  name="contact_lens_left_notes"
                                  type="text"
                                  v-model="form.contact_lens.left_notes"
                                  :state="getValidationState(validationContext)"
                                />
                                <b-form-invalid-feedback>
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                      </div>
                    </b-col>
                  </b-row>
                </section>

                <section>
                  <!-- IOGO-336 -->
                  <!-- <div class="box box--info box--icon mb-4">
              <div class="box-icon">
                <i class="di-information-10" />
              </div>
              <div class="box-header">
                <div class="box-header__title">
                  <p class="text-semi-bold mb-2">Source options</p>
                  <p>Quisque quis velit ex. Integer lacinia vehicula neque at dapibus.</p>
                </div>
              </div>
            </div> -->
                  <ValidationProvider
                    vid="source"
                    name="Source"
                    mode="lazy"
                    rules="required"
                    v-slot="validationContext"
                  >
                    <b-form-group label="Source" label-for="source">
                      <b-form-radio-group
                        id="source"
                        v-model="form.source_id"
                        name="source_id"
                        buttons
                        class="custom-radio-tag"
                        :state="getValidationState(validationContext)"
                      >
                        <b-form-radio
                          v-for="source in $store.state.prescriptionSources"
                          v-bind:key="source.value"
                          :value="source.value"
                          button
                          :button-variant="source.color"
                        >
                          <span>{{ source.text }}</span>
                        </b-form-radio>
                      </b-form-radio-group>
                      <b-form-invalid-feedback class="d-block">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                  <b-row>
                    <b-col cols="6">
                      <b-form-group label="Outlet" label-for="outlet">
                        <b-form-select
                          id="outlet"
                          :options="$store.getters.outletOptions"
                          v-model="form.outlet_id"
                        >
                        </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group label="Expiry date" label-for="expiry">
                        <b-form-input
                          id="expiry"
                          name="expiry_date_stamp"
                          type="date"
                          v-model="form.expiry_date_stamp"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-form-group label="Notes" label-for="note">
                    <b-form-textarea
                      id="note"
                      v-model="form.note"
                      rows="3"
                      placeholder="Type your notes here..."
                    />
                  </b-form-group>
                  <b-form-group label="Printed Notes" label-for="printed-note" class="mb-4">
                    <b-form-textarea
                      id="printed-note"
                      v-model="form.printed_note"
                      rows="3"
                      placeholder="Type your notes here..."
                    />
                  </b-form-group>
                  <b-form-group
                    v-if="form.files && form.files.length"
                    label="File"
                    label-for="prescription-file"
                    class="mb-4"
                  >
                    <FileCard
                      id="prescription-file"
                      :files="form.files"
                      removable
                      @removeFile="removePrescriptionFile"
                    />
                  </b-form-group>
                </section>
              </section>
            </b-form>
          </b-container>
        </b-col>
      </b-row>

      <template v-slot:modal-footer="{ cancel }">
        <div class="flex-fill m-0">
          <b-container>
            <div class="d-flex justify-content-end">
              <b-button variant="outline-secondary" @click="cancel()">Cancel</b-button>
              <b-button
                variant="primary"
                @click="passes(submit)"
                class="ml-3"
                :disabled="isLoading"
              >
                <b-spinner v-if="isLoading" small />
                <span v-else> {{ isEdit ? 'Save' : 'Create' }} Prescription </span>
              </b-button>
            </div>
          </b-container>
        </div>
      </template>
    </b-modal>
  </ValidationObserver>
</template>

<script>
import { mapState } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import VueMagnifier from '@/components/vue-magnifier.vue';
import apiPrescriptions from '../api/prescriptions';
import apiFiles from '../api/files';
import PrismInput from './PrismInput.vue';
import FilePreview from './FilePreview.vue';
import FileCard from './FileCard.vue';

export default {
  name: 'NewPrescriptionModal',
  components: {
    PrismInput,
    ValidationProvider,
    ValidationObserver,
    FilePreview,
    FileCard,
    VueMagnifier,
  },
  data() {
    return {
      enum_types: [
        {
          value: 'LOW',
          text: 'LOW',
        },
        {
          value: 'MEDIUM',
          text: 'MEDIUM',
        },
        {
          value: 'HIGH',
          text: 'HIGH',
        },
      ],
      addTypes: [
        {
          value: false,
          text: 'Binocular',
        },
        {
          value: true,
          text: 'Monocular',
        },
      ],
      isMonocular: false,
      isLoading: false,
      form: this.initForm(),
      file: null,
      fileError: '',
      near_add: null,
      int_add: null,
      errors: [],
      imageExtensions: ['png', 'jpg', 'jpeg'],
      newUploadImage: false,
    };
  },
  props: {
    customer: Object,
    prescription: Object,
    isOnPageEdit: Boolean,
  },
  computed: {
    ...mapState('auth', {
      selectedOutletId: state => state.selectedOutletId,
    }),
    isEdit() {
      return this.prescription && !!this.prescription.id;
    },
    prescriptionForm() {
      if (this.isEdit) {
        const { spectacles } = this.prescription;
        return {
          add_type: 'monocular',
          right_sph: this.prescription.right_sph,
          right_cyl: Number(this.prescription.right_cyl),
          right_axis: this.prescription.right_axis > 0 ? this.prescription.right_axis : null,
          left_sph: this.prescription.left_sph,
          left_cyl: Number(this.prescription.left_cyl),
          left_axis: this.prescription.left_axis > 0 ? this.prescription.left_axis : null,
          spectacles: {
            right_near_add: spectacles.right_near_add,
            right_int_add: spectacles.right_int_add,
            left_near_add: spectacles.left_near_add,
            left_int_add: spectacles.left_int_add,
            right_horiz_dist_prism: spectacles.right_horiz_dist_prism,
            right_horiz_near_prism: spectacles.right_horiz_near_prism,
            right_vert_dist_prism: spectacles.right_vert_dist_prism,
            right_vert_near_prism: spectacles.right_vert_near_prism,
            left_horiz_dist_prism: spectacles.left_horiz_dist_prism,
            left_horiz_near_prism: spectacles.left_horiz_near_prism,
            left_vert_dist_prism: spectacles.left_vert_dist_prism,
            left_vert_near_prism: spectacles.left_vert_near_prism,
          },
          contact_lens: {
            left_base_curve: this.prescription.contact_lens.left_base_curve,
            left_brand: this.prescription.contact_lens.left_brand,
            left_colour: this.prescription.contact_lens.left_colour,
            left_diameter: this.prescription.contact_lens.left_diameter,
            left_lens: this.prescription.contact_lens.left_lens,
            left_near_add: this.prescription.contact_lens.left_near_add,
            left_notes: this.prescription.contact_lens.left_notes,
            left_supplier: this.prescription.contact_lens.left_supplier,
            left_type: this.prescription.contact_lens.left_type,
            right_base_curve: this.prescription.contact_lens.right_base_curve,
            right_brand: this.prescription.contact_lens.right_brand,
            right_colour: this.prescription.contact_lens.right_colour,
            right_diameter: this.prescription.contact_lens.right_diameter,
            right_lens: this.prescription.contact_lens.right_lens,
            right_near_add: this.prescription.contact_lens.right_near_add,
            right_notes: this.prescription.contact_lens.right_notes,
            right_supplier: this.prescription.contact_lens.right_supplier,
            right_type: this.prescription.contact_lens.right_type,
            left_near_add_enum: this.prescription.contact_lens.left_near_add_enum,
            right_near_add_enum: this.prescription.contact_lens.right_near_add_enum,
          },
          type_id: this.prescription.type_id,
          source_id: this.prescription.source_id,
          outlet_id: this.prescription.outlet_id,
          note: this.prescription.note,
          printed_note: this.prescription.printed_note,
          files: this.prescription.files,
          expiry_date_stamp: this.prescription.expiry_date_stamp,
        };
      }
      return this.initForm();
    },
    showFile() {
      if (this.form.files && this.form.files[0]) {
        if (this.form.files[0].full_path) {
          return true;
        }
        return false;
      }
      return false;
    },
  },
  methods: {
    initForm() {
      return {
        add_type: 'monocular',
        right_sph: 0,
        right_cyl: 0,
        right_axis: null,
        left_sph: 0,
        left_cyl: 0,
        left_axis: null,
        spectacles: {
          right_near_add: null,
          right_int_add: null,
          left_near_add: null,
          left_int_add: null,
          right_horiz_dist_prism: null,
          right_horiz_near_prism: null,
          right_vert_dist_prism: null,
          right_vert_near_prism: null,
          left_horiz_dist_prism: null,
          left_horiz_near_prism: null,
          left_vert_dist_prism: null,
          left_vert_near_prism: null,
        },
        type_id: this._.get(this.$store.state.prescriptionTypes, '0.value', null),
        source_id: null,
        outlet_id: this.selectedOutletId,
        notes: null,
        printed_notes: null,
        expiry_date_stamp: null,
        contact_lens: {
          left_base_curve: null,
          left_brand: null,
          left_colour: null,
          left_diameter: null,
          left_lens: null,
          left_near_add: null,
          left_notes: null,
          left_supplier: null,
          left_type: null,
          right_base_curve: null,
          right_brand: null,
          right_colour: null,
          right_diameter: null,
          right_lens: null,
          right_near_add: null,
          right_notes: null,
          right_supplier: null,
          right_type: null,
          left_near_add_enum: null,
          right_near_add_enum: null,
        },
      };
    },
    initData() {
      return {
        addTypes: [
          {
            value: false,
            text: 'Binocular',
          },
          {
            value: true,
            text: 'Monocular',
          },
        ],
        isMonocular: false,
        isLoading: false,
        form: this.initForm(),
        near_add: null,
        int_add: null,
        errors: [],
        file: null,
        fileError: '',
      };
    },
    onHide() {
      Object.assign(this.$data, this.initData());
    },
    onShow() {
      this.$nextTick(() => {
        if (this.isEdit) {
          const { spectacles } = this.prescription;
          if (spectacles.left_near_add === spectacles.right_near_add) {
            this.near_add = spectacles.left_near_add;
          }
          if (spectacles.left_int_add === spectacles.right_int_add) {
            this.int_add = spectacles.left_int_add;
          }
          this.form = this.prescriptionForm;
        } else {
          this.form.outlet_id = this.selectedOutletId;
        }
      });
    },
    onOk(e) {
      this.$emit('ok', e);
    },
    updateBothNearAdd() {
      this.form.spectacles.right_near_add = this.near_add;
      this.form.spectacles.left_near_add = this.near_add;
    },
    updateBothIntAdd() {
      this.form.spectacles.right_int_add = this.int_add;
      this.form.spectacles.left_int_add = this.int_add;
    },
    async submit() {
      this.isLoading = true;
      if (this.newUploadImage && this.form.files && this.form.files.length > 0) {
        const fileId = this.form.files[0].id;
        this.$set(this.form, 'files', []);
        this.$set(this.form, 'files', [fileId]);
      }
      if (this.isEdit) {
        apiPrescriptions
          .putPrescription(this.prescription.id, this.form)
          .then(response => {
            this.$nextTick(() => {
              this.$refs.newPrescriptionModal.hide('ok');
              this.$store.dispatch(
                'showSuccessAlert',
                'Prescription has been successfully edited!',
              );
            });
            if (this.isOnPageEdit) {
              this.$emit('prescriptionDataSent', {
                prescription: { ...this.prescription },
              });

              if (this.customer) {
                this.$store.commit('jobsModule/SET_ACTIVE_JOB_EDIT_STEP', 3);
              } else {
                this.$store.commit('jobsModule/SET_ACTIVE_JOB_EDIT_STEP', 4);
              }
            }

            if (!this.customer) {
              this.$store.commit('jobsModule/SET_SELECTED_PRESCRIPTION', response.data.data);
            }
          })
          .catch(({ response }) => {
            this.$store.dispatch('showErrorAlert', response.data.error);
          })
          .finally(() => {
            if (this.customer) {
              this.$root.$emit('prescriptionListMutated', this.prescription.id);
            }
            this.isLoading = false;
          });
      } else {
        /* eslint-disable */
        if (this.customer) {
          apiPrescriptions
            .postCustomerPrescription(this.customer.id, this.form)
            .then(response => {
              const error = this._.get(response, 'data.error');
              const data = this._.get(response, 'data.data');
              if (error) {
                let errorText = error;
                if (data && data.length > 0) {
                  data.map(err => {
                    errorText += ` ${err}`;
                  });
                }
                this.$store.dispatch('showErrorAlert', errorText);
                return;
              }
              this.$nextTick(() => {
                this.$refs.newPrescriptionModal.hide('ok');
                this.$store.dispatch(
                  'showSuccessAlert',
                  'New Prescription has been successfully created!',
                );
                this.$root.$emit('prescriptionListMutated');
              });
            })
            .catch(({ response }) => {
              this.$store.dispatch('showErrorAlert', response.data.error);
            })
            .finally(() => {
              this.$root.$emit('prescriptionListMutated');
              this.isLoading = false;
            });
        } else {
          apiPrescriptions
            .postGuestPrescription(this.form)
            .then(response => {
              const error = this._.get(response, 'data.error');
              const data = this._.get(response, 'data.data');
              if (error) {
                let errorText = error;
                if (data && data.length > 0) {
                  data.map(err => {
                    errorText += ` ${err}`;
                  });
                }
                this.$store.dispatch('showErrorAlert', errorText);
                return;
              }
              this.$nextTick(() => {
                this.$refs.newPrescriptionModal.hide('ok');
                this.$store.dispatch(
                  'showSuccessAlert',
                  'New Prescription has been successfully created!',
                );
                this.$store.commit('jobsModule/SET_SELECTED_PRESCRIPTION', response.data);
                this.$bvModal.show('new-pd-modal');
              });
            })
            .catch(({ response }) => {
              this.$store.dispatch('showErrorAlert', response.data.error);
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      }
    },
    async handleFileInputChange(e) {
      this.fileError = '';
      this.file = null;
      const { valid } = await this.$refs.fileValidator.validate(e);

      if (valid) {
        [this.file] = e.target.files;

        this.isLoading = true;
        const uploadedFile = await apiFiles.createFile(this.file);
        if (uploadedFile) {
          this.newUploadImage = true;
          this.$set(this.form, 'files', [uploadedFile.data.data]);
          if (this.isEdit) {
            await apiFiles.attachFile(
              [uploadedFile.data.data.id],
              this.prescription.id,
              this.customer.id,
            );
          }
        }

        this.isLoading = false;
      } else {
        this.fileError = 'File must e an image, pdf or doc/docx';
      }
    },
    removePrescriptionFile(fileId) {
      this.isLoading = true;
      apiPrescriptions
        .deletePrescriptionFile(this.customer.id, this.prescription.id, fileId)
        .then(() => {
          this.$delete(this.form, 'files');
          this.$delete(this.prescription, 'files');
          this.$store.dispatch('showSuccessAlert', 'File deleted from prescription');
        })
        .catch(() => {
          this.$store.dispatch('showErrorAlert', 'File could not delete');
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  watch: {
    isMonocular() {
      if (this.isMonocular === false) {
        this.updateBothNearAdd();
        this.updateBothIntAdd();
      }
    },
  },
  directives: {
    disableIncrement: {
      bind(el) {
        // Disable mouse wheel increment/decrement
        el.addEventListener('wheel', e => {
          e.preventDefault();
        });

        // Disable arrow key increment/decrement
        el.addEventListener('keydown', e => {
          if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            e.preventDefault();
          }
        });
      },
    },
  },
};
</script>

<style>
.modal-fullscreen .modal-dialog {
  max-width: 100%;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  display: flex;
}
#modal-fullscreen-bg {
  background: #fff;
}
</style>
